import axios from '../axios';
import getAvailableEventTeachersApi from './getAvailableEventTeachersApi';
import getListEventsApi from './getListEventsApi';
import getTeacherAvailableEventsApi from './getTeacherAvailableEventsApi';
import patchReserveAvailableApi from './patchReserveAvailableApi';

const getMe = () => axios.get('/api/me')

const updateMe = (data) => axios.put("api/me", data);

const getSetupIntent = () => axios.get('api/stripe/setup-intent')

const chargeEma = (data) => axios.post('api/plans/charge-ema', data)

const subscribe = (data) => axios.post('api/plans/subscribe', data)

const getPlans = () => axios.get('api/plans')

const getPlanOption = (data) => axios.post('api/plan-options', data)

const cancelCurrent = (data) => axios.post('api/plans/cancel-current', data)

const pauseCurrent = (data) => axios.post('api/plans/pause-current', data)

export default {
  getMe,
  updateMe,
  getSetupIntent,
  chargeEma,
  subscribe,
  getPlans,
  getPlanOption,
  cancelCurrent,
  pauseCurrent,
  getListEventsApi,
  getTeacherAvailableEventsApi,
  getAvailableEventTeachersApi,
  patchReserveAvailableApi
}
